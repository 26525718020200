import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import HeroRow from '../components/hero-row'
import './index.css'
import { Link } from 'gatsby'
import Carousel from '../components/carousel'

class RootIndex extends React.Component {

  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [hero] = get(this, 'props.data.allContentfulHero.edges')

    return (
      <Layout location={this.props.location}>
        <div className="container-fluid">
          <HeroRow text={hero.node.text} subtext={hero.node.subtext}/>
          <div className="row row-home">
            <div className="col-md-7 col-padding">
              <Carousel images={hero.node.images} index={0}/>
            </div>
            <div className="col-md-5 text-center col-padding">
              <h2>Senior living on your terms.</h2>
              <p>A Home Care Suite is a custom cottage or in-law suite built in your family's backyard. Made for elderly living, a Home Care Suite is a safe and affordable alternative to expensive assisted living in Tampa Bay Area.</p>
              <Link to="/contact" className="btn btn-primary"> Free consultation</Link>
            </div>   
          </div>
          <div className="row row-mini justify-content-center">
            <div className="col-md-6 text-center col-padding">
              <h2 className="section-headline">A new alternative.</h2>
              <p>As an alternative to spending thousands/month on Assisted Living, you can increase your home value with more square feet!</p>
              <Link to="/" className="btn btn-primary">Find out how</Link>
            </div>
          </div>
          <div className="row row-home">
            <div className="col-md-6 text-center col-padding">
              <h2 className="section-headline">Features for living.</h2>
              <p>Home Care Suites provides numerous customizable options.  During custruction, our customers will have the unique opportunity to select colors, cabinetry, hardware, lighting, and many more configurations.  See how our past customers have customized their cottage.</p>
              <Link to="/portfolio"><button className="btn btn-primary">See customizations</button></Link>
            </div>
            <div className="col-md-6 text-center col-padding">
              <iframe width="100%" height="450px" src="https://www.youtube.com/embed/xOBcihOZ1QQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="row row-mini">
              {posts.map(({ node }) => {
                return (
                  <div className="col-md-4" key={node.slug}>
                    <ArticlePreview article={node} />
                  </div>
                )
              })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulHero(
      filter: { contentful_id: { eq: "1cgyqwoPGglthHQkQ9GBof" } }
    ) {
      edges {
        node {
          name
          text
          subtext
          images {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
