import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './article-preview.css'

export default ({ article }) => (
  <div className="article-preview">
    <Img alt="" fluid={article.heroImage.fluid} />
    <h3 style={{marginTop:"20px"}}>
      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    </h3>
    <small>{article.publishDate}</small>
    <div
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    {article.tags &&
      article.tags.map(tag => (
        <p className="tag" key={tag}>
          {tag}
        </p>
      ))}
  </div>
)
