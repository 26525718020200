import React from 'react'
import Img from 'gatsby-image'

export default ({
  images,
  index
}) => (
    <div id={"carousel" + index} className="carousel slide" data-ride="carousel">
      <div style={{maxHeight:"500px"}}className="carousel-inner">
        <div className="carousel-item active">
          <Img 
            alt={images[0].name}
            fluid={images[0].fluid}
          />
        </div>
        <div className="carousel-item">
          <Img 
            alt={images[1].name}
            fluid={images[1].fluid}
          />
        </div>
        <div className="carousel-item">
          <Img 
            alt={images[2].name}
            fluid={images[2].fluid}
          />
        </div>
        <div className="carousel-item">
          <Img 
            alt={images[3].name}
            fluid={images[3].fluid}
          />
        </div>
        {images.length > 4 &&
          <div className="carousel-item">
            <Img 
              alt={images[4].name}
              fluid={images[4].fluid}
            />
          </div>
        }
        {images.length > 5 &&
          <div className="carousel-item">
            <Img 
              alt={images[5].name}
              fluid={images[5].fluid}
            />
          </div>
        }
        {images.length > 6 &&
          <div className="carousel-item">
            <Img 
              alt={images[6].name}
              fluid={images[6].fluid}
            />
          </div>
        }
        {images.length > 7 &&
          <div className="carousel-item">
            <Img 
              alt={images[7].name}
              fluid={images[7].fluid}
            />
          </div>
        }
      </div>
      <a className="carousel-control-prev" href={"#carousel" + index} role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href={"#carousel" + index} role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
)